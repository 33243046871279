"use strict";
(function(global, factory) {
  typeof exports === "object" && typeof module !== "undefined"
    ? (module.exports = factory())
    : typeof define === "function" && define.amd
    ? define(factory)
    : (global.taskrunner = factory());
})(window, function() {
  var taskList = [];
  var currentTaskNumber = 0;
  var taskHandle = null;
  var enableLog = false;
  window.requestIdleCallback =
    window.requestIdleCallback ||
    function(handler) {
      var startTime = Date.now();

      return setTimeout(function() {
        handler({
          didTimeout: false,
          timeRemaining: function() {
            return Math.max(0, 50.0 - (Date.now() - startTime));
          }
        });
      }, 1);
    };
  window.cancelIdleCallback =
    window.cancelIdleCallback ||
    function(id) {
      clearTimeout(id);
    };

  function enqueueTask(taskHandler, taskData, taskName) {
    taskList.push({
      handler: taskHandler,
      data: taskData,
      taskName: taskName
    });

    if (!taskHandle) {
      taskHandle = requestIdleCallback(runTaskQueue, {
        timeout: 3000
      });
    }
  }

  function runTaskQueue(deadline) {
    while (
      (deadline.timeRemaining() > 0 || deadline.didTimeout) &&
      taskList.length
    ) {
      if (enableLog) console.log(deadline.timeRemaining());
      let task = taskList.shift();
      currentTaskNumber++;
      if (enableLog)
        console.log(task.taskName, "---running---", currentTaskNumber);
      task.handler(task.data);
    }

    if (taskList.length) {
      taskHandle = requestIdleCallback(runTaskQueue, {
        timeout: 3000
      });
    } else {
      taskHandle = 0;
    }
  }
  var addTask = function(handler, taskData, taskName) {
    enqueueTask(handler, taskData, taskName);
  };
  return {
    addTask: addTask
  };
});

/*const linkConvertor = (path, extra) => ({
  path: `/${path}?${extra && extra.query ? extra.query : ""}`
}); */

const linkMap = {
  //"your-rides": linkConvertor.bind(null, "bookings"),
  details: function(extra) {
    return {
      path: `/details?${extra && extra.query ? extra.query : ""}`
    };
  },
  listings: function(extra) {
    return {
      path: `/listings?${extra && extra.query ? extra.query : ""}`
    };
  }
};

const appMixin = {
  computed: {
    transitionName() {
      return this.$store.state.activeTransitionName;
    },
    appStrings() {
      return this.$store.state.appStrings;
    },
    currencyUnit() {
      return this.$store.state.currencyUnit;
    },
    mileageMetricUnit() {
      return this.$store.state.mileageMetricUnit;
    },
    topScoreThreshold() {
      return this.$store.state.topScoreThreshold;
    },
    selectedCityData() {
      return this.$store &&
        this.$store.state &&
        this.$store.state.selectedCityData
        ? this.$store.state.selectedCityData
        : "";
    },
    isIos() {
      return (
        this.getOS() === "ios" ||
        (window.serverData &&
          window.serverData.dataFromApp &&
          window.serverData.dataFromApp.os === "ios")
      );
    },
    isMobile() {
      let _isMobile = window.matchMedia("screen and (max-width: 768px)")
        .matches;
      return _isMobile ? true : false;
    },
    isWebview() {
      let isWebView = window.serverData.isWebview;
      if (!isWebView && window.localStorage) {
        let lsIsWebview = localStorage.getItem("isWebview");
        isWebView = lsIsWebview == "true" ? true : false;
      }
      return isWebView;
    },
    isApiTriggeredToDecideOutlet() {
      return (
        !this.$store.state.retailOutletStatus ||
        (this.$store.state.retailOutletStatus &&
          !this.$store.state.retailOutletStatus.apiTriggered)
      );
    },
    isRetailOutletEnabled() {
      return (
        this.$store.state.retailOutletStatus &&
        this.$store.state.retailOutletStatus.apiTriggered &&
        this.$store.state.retailOutletStatus.isRetailOutlet
      );
    }
  },
  data() {
    return {
      DEFAULT_TRANSITION: "slide-right",
      eventQueue: [],
      showBenefits: false
    };
  },
  methods: {
    getHighlightedText(
      originalText,
      className = "highlight",
      forceHighlight = false
    ) {
      let firstIndex = originalText.indexOf("[");
      let lastIndex = originalText.lastIndexOf("]");
      if (firstIndex >= 0 && lastIndex > 0) {
        let firstPart = originalText.slice(0, firstIndex);
        let lastPart = originalText.slice(lastIndex + 1);
        let highlightedPart = originalText.slice(firstIndex + 1, lastIndex);
        if (forceHighlight) {
          return `${firstPart}<span class="${className}">${highlightedPart}</span>${lastPart}`;
        }

        if (this.isMobile && className === "highlight") {
          return `${firstPart}${highlightedPart}${lastPart}`;
        } else {
          return `${firstPart}<span class="${className}">${highlightedPart}</span>${lastPart}`;
        }
      }
      return originalText;
    },
    getHighlightedTextTransition(originalText, className = "highlight") {
      let firstIndex = originalText.indexOf("[");
      let lastIndex = originalText.lastIndexOf("]");
      if (firstIndex >= 0 && lastIndex > 0) {
        let firstPart = originalText.slice(0, firstIndex);
        let lastPart = originalText.slice(lastIndex + 1);
        let highlightedPart = originalText.slice(firstIndex + 1, lastIndex);

        return `${firstPart}<span class="${className}">${highlightedPart}</span>${lastPart}`;
      }
      return originalText;
    },
    capitalizeFirstLetter(text) {
      if (!text) return "";
      text = text.toString();
      return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
    },
    getIntInIndianNumSystem(number, fraction = 0) {
      if (!number) return null;
      return number.toLocaleString("en-IN", {
        maximumFractionDigits: fraction
      });
    },
    getSubtractedNum(number, number2, fraction = 0) {
      let num = number - number2;
      if (!num) return null;
      return num.toLocaleString("en-IN", {
        maximumFractionDigits: fraction
      });
    },
    getDateFormat(selectedDate, type) {
      let days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday"
      ];
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      let date = new Date(selectedDate);
      let dayName = days[date.getDay()];
      let day = date.getDate();
      let monthName = months[date.getMonth()];
      let year = date.getFullYear();
      let time = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
      if (type && type === "dateMonthTime") {
        return `${day} ${monthName}, ${time}`;
      }
      if (type && type === "dateMonthYear") {
        return `${day} ${monthName} ${year}`;
      }
      if (type && type === "dateMonthYearWithSeparator") {
        return `${day} ${monthName}, ${year}`;
      }
      return `${dayName}, ${day} ${monthName}`;
    },
    addDefaultImage(event) {
      event.target.onerror = null;
      event.target.src = this.getCdnUrl(
        true,
        "pwa-asset/Car_image_placeholder/placeholder_olacars@2x.png"
      );
    },
    pushEvents(data, sendViaFetch = false) {
      if (!data) return;
      data.eventValue = data.eventValue || {};
      const ev =
        typeof data.eventValue === "object"
          ? JSON.stringify(data.eventValue)
          : data.eventValue;
      const params = {
        eventName: data.eventName,
        eventValue: ev,
        browserName: this.getBrowser(),
        url: window.location.pathname,
        platform: this.getPlatform()
      };
      if (window.owConfig.apiBase) {
        params.domain = window.location.hostname + window.owConfig.apiBase;
      } else {
        params.domain = window.location.hostname;
      }
      this.triggerEventApi(
        params,
        sendViaFetch,
        window.owConfig.fabricEndpoint
      );
    },
    pushBatchEvents(batchData, sendViaFetch = false) {
      if (!batchData && !batchData.length) return;
      let batchEventData = [];
      batchData.forEach(data => {
        data.eventValue = data.eventValue || {};
        const ev =
          typeof data.eventValue === "object"
            ? JSON.stringify(data.eventValue)
            : data.eventValue;
        const params = {
          eventName: data.eventName,
          eventValue: ev,
          browserName: this.getBrowser(),
          url: window.location.pathname,
          platform: this.getPlatform()
        };
        if (window.owConfig.apiBase) {
          params.domain = window.location.hostname + window.owConfig.apiBase;
        } else {
          params.domain = window.location.hostname;
        }
        batchEventData.push(params);
      });
      this.triggerEventApi(
        batchEventData,
        sendViaFetch,
        window.owConfig.fabricBatchEndpoint
      );
    },
    triggerEventApi(params, sendViaFetch, url) {
      if (!sendViaFetch) {
        const http = new XMLHttpRequest();
        http.open("POST", url, true);
        http.setRequestHeader("Content-type", "application/json");
        http.setRequestHeader(
          "csrf-token",
          window.rxReadCookie && window.rxReadCookie("XSRF-TOKEN")
        );
        if (window.serverData.dataFromApp) {
          if (window.serverData.dataFromApp.authorization) {
            http.setRequestHeader(
              "wasc",
              window.serverData.dataFromApp.authorization
            );
          }
          if (window.serverData.dataFromApp.app_session_id) {
            http.setRequestHeader(
              "app_session_id",
              window.serverData.dataFromApp.app_session_id
            );
          }
        }
        http.send(JSON.stringify(params));
      } else {
        if (!("fetch" in window)) {
          return;
        }
        let reqHeaders = {
          "Content-Type": "application/json",
          "csrf-token": window.rxReadCookie && window.rxReadCookie("XSRF-TOKEN")
        };
        if (window.serverData.dataFromApp) {
          if (window.serverData.dataFromApp.authorization) {
            reqHeaders["wasc"] = window.serverData.dataFromApp.authorization;
          }
          if (window.serverData.dataFromApp.app_session_id) {
            reqHeaders["app_session_id"] =
              window.serverData.dataFromApp.app_session_id;
          }
        }
        fetch(url, {
          keepalive: true,
          method: "POST",
          headers: reqHeaders,
          body: JSON.stringify(params)
        });
      }
    },
    getSessionId(fingerPrint) {
      return fingerPrint + "_" + sessionStorage.getItem("userSessionTime");
    },
    triggerTrackEvents(
      eventName,
      eventField,
      forcePush = false,
      sendViaFetch = false
    ) {
      this.getDeviceId().then(deviceId => {
        let fingerPrint = deviceId;
        if (!eventName) return;
        let date = new Date();
        let dataFromApp = window.serverData && window.serverData.dataFromApp;
        const {
          utm_medium = localStorage.getItem("utm_medium") || "",
          utm_source = localStorage.getItem("utm_source") || "",
          utm_campaign = localStorage.getItem("utm_campaign") || ""
        } = this.$route.query;
        let queryParams = { utm_campaign, utm_source, utm_medium };
        let traffic_source = "";
        if (this.isWebview) {
          traffic_source = "PWA";
        } else if (this.getPlatform() == "desktop") {
          traffic_source = "Desktop";
        } else {
          traffic_source = "MobileWeb";
        }

        let isUserLoggedIn = localStorage.getItem("userLoggedIn");

        let eventObj = {
          timestamp: date.getTime(),
          date: date.getDate(),
          hr: date.getHours(),
          oc_os: this.getOS(),
          app_version: (dataFromApp && dataFromApp["app_version"]) || "",
          user_lat: (dataFromApp && dataFromApp["current_lat"]) || "",
          user_long: (dataFromApp && dataFromApp["current_lng"]) || "",
          choice_city:
            (this.selectedCityData && this.selectedCityData.name) || "",
          map_selected_city:
            (dataFromApp && dataFromApp["pickup_city_name"]) || "",
          map_selected_lat: (dataFromApp && dataFromApp["pickup_lat"]) || "",
          map_selected_long: (dataFromApp && dataFromApp["pickup_lng"]) || "",
          app_session_id: (dataFromApp && dataFromApp["app_session_id"]) || "",
          fingerPrint: fingerPrint,
          x_session_id: this.getSessionId(fingerPrint),
          source_ingress: (dataFromApp && dataFromApp["source"]) || "",
          website: !this.isViaMobile(),
          traffic_source,
          ...queryParams,
          is_user_loggedin: this.isWebview || isUserLoggedIn == "yes",
          bottom_nav_active: this.$store.state.bottomNavActive,
          bottom_nav_space_occupied: this.$store.state.bottomNavSpaceOccupied
        };

        if (eventField) {
          Object.assign(eventObj, eventField);
        }

        let userInfo = this.$store.state.userDetails;
        Object.assign(eventObj, {
          user_name: (userInfo && userInfo.name) || "NA",
          user_email_id:
            (userInfo &&
              userInfo.identities &&
              userInfo.identities.find(i => i.type == "EMAIL").value) ||
            "NA",
          user_phone_number:
            (userInfo &&
              userInfo.identities &&
              userInfo.identities.find(i => i.type == "PHONE").value) ||
            "NA",
          user_id: (userInfo && userInfo.userId) || "NA"
        });

        const data = {
          eventName: eventName,
          eventValue: eventObj
        };
        if (window.owConfig.isAllowBatchEvents) {
          if (forcePush) {
            window.batchEvents.push(data);
            this.pushBatchEvents(window.batchEvents, sendViaFetch);
            window.batchEvents = [];
          } else if (window.batchEvents.length < window.owConfig.batchLimit) {
            window.batchEvents.push(data);
          } else if (window.batchEvents && window.batchEvents.length) {
            this.pushBatchEvents(window.batchEvents);
            window.batchEvents = [];
            window.batchEvents.push(data);
          }
        } else {
          if (forcePush) {
            this.pushEvents(data, sendViaFetch);
          } else {
            /* Batch the events before publishing */
            this.eventQueue.push(data);
            setTimeout(() => {
              if (window.taskrunner) {
                while (this.eventQueue.length) {
                  var value = this.eventQueue.shift();
                  window.taskrunner.addTask(
                    this.pushEvents.bind(this),
                    value,
                    "TASK_POST_EVENT"
                  );
                }
              }
            }, 2000);
          }
        }

        this.GTMEventPush(eventObj, eventName);
        setTimeout(() => {
          this.moEngageEventPush(eventObj, eventName, fingerPrint);
        }, 2000);
      });
    },
    triggerBatchEventOnPageLeft() {
      if (window.batchEvents.length) {
        this.pushBatchEvents(window.batchEvents, true);
        window.batchEvents = [];
      }
    },
    moEngageEventPush(eventObj, eventName, fingerPrint) {
      let obj = eventObj || {};
      Object.assign(obj, {
        user_language: "English",
        device_type:
          (window.serverData &&
            window.serverData.dataFromApp &&
            window.serverData.dataFromApp.device_type) ||
          this.getDeviceType(),
        os_version:
          (window.serverData &&
            window.serverData.dataFromApp &&
            window.serverData.dataFromApp.os_version) ||
          "",
        screen_dpi:
          (window.serverData &&
            window.serverData.dataFromApp &&
            window.serverData.dataFromApp.screen_dpi) ||
          window.devicePixelRatio,
        screen_height:
          (window.serverData &&
            window.serverData.dataFromApp &&
            window.serverData.dataFromApp.screen_height) ||
          window.innerHeight,
        screen_width:
          (window.serverData &&
            window.serverData.dataFromApp &&
            window.serverData.dataFromApp.screen_width) ||
          window.innerWidth,
        network_type:
          (window.serverData &&
            window.serverData.dataFromApp &&
            window.serverData.dataFromApp.network_type) ||
          this.getNetworkType(),
        device_model:
          (window.serverData &&
            window.serverData.dataFromApp &&
            window.serverData.dataFromApp.device_model) ||
          "",
        oc_platform: this.getPlatform(),
        app_name: "olacabs",
        domain: window.owConfig.apiBase
          ? window.location.hostname + window.owConfig.apiBase
          : window.location.hostname
      });

      let userInfo = this.$store.state.userDetails;
      Object.assign(obj, {
        user_name: (userInfo && userInfo.name) || "NA",
        user_email_id:
          (userInfo &&
            userInfo.identities &&
            userInfo.identities.find(i => i.type == "EMAIL").value) ||
          "NA",
        user_phone_number:
          (userInfo &&
            userInfo.identities &&
            userInfo.identities.find(i => i.type == "PHONE").value) ||
          "NA",
        user_id: (userInfo && userInfo.userId) || "NA"
      });

      Object.entries(obj).forEach(item => {
        if (typeof item[1] === "object") {
          obj[item[0]] = JSON.stringify(item[1]);
        }
      });
      if (!window.Moengage) {
        if (!window.moengageQueue) {
          window.moengageQueue = [];
        }
        window.moengageQueue.push({
          name: this.getMoengageEventName(eventName),
          value: obj
        });
      } else {
        window.Moengage.track_event(this.getMoengageEventName(eventName), obj);
      }

      if (userInfo.userId) {
        if (window.Moengage) {
          window.Moengage.add_unique_user_id(userInfo.userId);
          window.Moengage.add_user_attribute("oc_device_id", fingerPrint);
          window.Moengage.add_user_attribute("oc_user_login_state", "login");
          window.Moengage.add_user_attribute(
            "oc_email",
            (userInfo &&
              userInfo.identities &&
              userInfo.identities.find(i => i.type == "EMAIL").value) ||
              "NA"
          );
          window.Moengage.add_user_attribute(
            "oc_mobile_number",
            "+91" +
              (userInfo &&
                userInfo.identities &&
                userInfo.identities.find(i => i.type == "PHONE").value) || "NA"
          );
          window.Moengage.add_user_attribute(
            "oc_user_name",
            (userInfo && userInfo.name) || "NA"
          );
        }
      } else {
        if (window.Moengage) {
          window.Moengage.add_user_attribute("oc_device_id", fingerPrint);
          window.Moengage.add_user_attribute(
            "oc_user_login_state",
            "anonymous"
          );
        }
      }
    },
    getMoengageEventName(name) {
      return "oc_" + name.toLowerCase();
    },
    getDeviceType() {
      if (window.innerWidth < 768) return "mobile";
      else if (window.innerWidth < 1025) return "ipad";
      else return "desktop";
    },
    getNetworkType() {
      var connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection;

      if (!connection) {
        return "NA";
      }

      return connection.type ? connection.type : "";
    },
    navigateToPage(
      { name, params, query },
      isReplace,
      transobj = { transitionName: "slide-left" }
    ) {
      if (!this.isMobile) {
        transobj = { transitionName: "" };
      }
      if (isReplace) {
        this.$router.replace({
          name,
          params: Object.assign({}, params, transobj),
          query: Object.assign({}, this.$route.query, query)
        });
      } else {
        this.$router.push({
          name,
          params: Object.assign({}, params, transobj),
          query: Object.assign({}, this.$route.query, query)
        });
      }
    },
    lazyLoadImages(targetId) {
      if (targetId) {
        const container = document.getElementById(targetId);
        const allImg = container.querySelectorAll("img.lazy");

        Array.from(allImg).forEach(img => {
          if (!img.src) {
            img.src = img.dataset.src;
            img.classList.remove("lazy");
          }
        });
      }
    },
    getCdnUrl(baseUrl, url, delivery_profile) {
      if (!baseUrl) {
        return url;
      } else if (url && typeof url !== "object" && url.indexOf("https") > -1) {
        return url;
      } else {
        if (!url) {
          return "";
        }
        const cdnType = window.owConfig.cdnType;
        const cloudinaryCdnDownloadUrl =
          window.owConfig.cloudinaryCdnDownloadUrl;
        const cloudinaryEnabledFor = window.owConfig.cloudinaryEnabledFor;
        const citySelected =
          (this.selectedCityData && this.selectedCityData.name) ||
          localStorage.getItem("city") ||
          "";
        const appBaseUrl =
          cdnType === "cloudinary" &&
          cloudinaryEnabledFor.includes(citySelected)
            ? cloudinaryCdnDownloadUrl
            : window.owConfig.cdnDownloadUrl;

        if (appBaseUrl) {
          let cdnurl = url;
          if (
            cdnType === "cloudinary" &&
            cloudinaryEnabledFor.includes(citySelected)
          ) {
            if (
              delivery_profile &&
              delivery_profile !== "details" &&
              delivery_profile !== "list-sharpen"
            ) {
              cdnurl = delivery_profile + "/" + url + "?tx=e_sharpen:50";
            } else if (delivery_profile) {
              cdnurl = delivery_profile + "/" + url;
            } else if (url.includes(".svg")) {
              cdnurl = "svg/" + url;
            }
          } else {
            cdnurl = url;
          }
          return appBaseUrl.replace("{imageCdnId}", cdnurl);
        }
      }
      return baseUrl + url;
    },
    closeWebView() {
      const ref = window.OlaJSNativeBridge.addAppEventListener(
        "closeWebView",
        () => {},
        {}
      );
      window.OlaJSNativeBridge.removeAppEventListener(ref);
    },
    branchEventTrigger(eventName, attributes = {}) {
      if (this.isViaMobile()) {
        let params = {
          eventName,
          attributes
        };
        const ref = window.OlaJSNativeBridge.addAppEventListener(
          "branchEvent",
          () => {},
          params
        );
        window.OlaJSNativeBridge.removeAppEventListener(ref);
      }
      // else {
      //   this.GTMEventPush(attributes, eventName);
      // }
    },
    handleBackAction() {
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.closeWebView();
      }
    },
    isViaMobile() {
      return window.serverData.isMobile ? true : false;
    },
    getInstrumentationPageName(routeName) {
      let map = {
        "ve-dashboard": "Home",
        "ve-listings": "Listings",
        "ve-details": "Details",
        "ve-my-accounts": "Accounts",
        "ve-seller-dashboard": "Seller Home",
        "ve-user-details": "User Details",
        "favourite-page": "favourites",
        "ve-favourites": "favourites",
        "ve-outlets-listings": "Outlets Page"
      };
      return map[routeName] || routeName;
    },
    recentlyViewedPageData(pageData = {}) {
      if (pageData && pageData.page) {
        pageData.page = this.getInstrumentationPageName(pageData.page);
      }
      let defaultPageAttr = {
        page: null,
        section: null,
        item_clicked: null,
        car_id: null,
        car_position: null,
        is_default_filter: null,
        is_default_sort: null,
        filters_applied_data: null,
        sort_applied_data: null,
        applied_filter: null,
        action_performed: null
      };
      this.$store.dispatch(
        "SET_LAST_RECENTLY_VIEWED_PAGE",
        Object.assign(defaultPageAttr, pageData)
      );
    },
    removeExtraChars(originalText) {
      if (originalText) {
        let firstIndex = originalText.indexOf("[");
        let lastIndex = originalText.lastIndexOf("]");
        if (firstIndex >= 0 && lastIndex > 0) {
          let firstPart = originalText.slice(0, firstIndex);
          let lastPart = originalText.slice(lastIndex + 1);
          let highlightedPart = originalText.slice(firstIndex + 1, lastIndex);
          return `${firstPart}${highlightedPart}${lastPart}`;
        }
        return originalText;
      }
      return "";
    },
    storeStaticConfig(dashboardConfig) {
      if (dashboardConfig) {
        if (!this.mileageMetricUnit && dashboardConfig.mileageMetricUnit) {
          this.$store.dispatch(
            "SET_MILEAGE_UNIT",
            dashboardConfig.mileageMetricUnit
          );
        }
        if (!this.topScoreThreshold && dashboardConfig.topScoreThreshold) {
          this.$store.dispatch(
            "SET_TOP_SCORE_THRESHOLD",
            dashboardConfig.topScoreThreshold
          );
        }
        if (dashboardConfig && dashboardConfig.emi_calculator_configs) {
          dashboardConfig.EMICalculatorConfig = dashboardConfig.emi_calculator_configs.map(
            item => {
              return {
                initialValue: item.initial_value,
                rangeMin: item.range_min,
                rangeMax: item.range_max,
                rangeStep: item.range_step,
                rangeUnit: item.range_unit,
                rangeUnitShort: item.range_unit_short,
                rangeUnitValue: item.range_unit_value,
                rangeModel: item.range_model,
                currencyUnit: item.currency_unit,
                displayValues: item.display_values,
                allowDecimal: item.allow_decimal,
                display: item.display,
                suffix: item.suffix
              };
            }
          );
        }
        if (dashboardConfig && dashboardConfig.how_it_works_data) {
          dashboardConfig.howItWorksData = dashboardConfig.how_it_works_data.map(
            item => {
              return {
                contentType: item.content_type,
                contentSrc: item.content_src,
                header: item.header,
                subheader: item.sub_header,
                preview: item.preview ? item.preview : "",
                go_live: item.go_live ? item.go_live : "",
                country: item.country ? item.country : ""
              };
            }
          );
        }
        if (dashboardConfig && dashboardConfig.brands_filter_data) {
          dashboardConfig.brandsFilterData = dashboardConfig.brands_filter_data.map(
            item => {
              return {
                subheader: item.sub_header,
                image_src: item.image_src,
                bgcolor: item.bg_color,
                vasBrandName: item.vas_brand_name,
                filters: item.filters
              };
            }
          );
        }
        this.$store.dispatch("SET_STATIC_CONFIG", dashboardConfig);
      }
    },
    loadHomeSSRComponents() {
      this.loadOWComponent("ve-dashboard", "pages/ve-dashboard.js");
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent("shimmer-blocks", "components/shimmer-blocks.js");
      this.loadOWComponent("story-view", "components/story-view.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent(
        "owvc-common-styles",
        "components/owvc-common-styles.js"
      );
    },
    loadListingsSSRComponents() {
      this.loadOWComponent("ve-listings", "pages/ve-listings.js");
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent(
        "owvc-common-styles",
        "components/owvc-common-styles.js"
      );
    },
    loadDetailsSSRComponents() {
      this.loadOWComponent("ve-details", "pages/ve-details.js");
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent(
        "owvc-common-styles",
        "components/owvc-common-styles.js"
      );
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
    },
    loadCommonComponents() {
      this.loadOWComponent("ow-ajax", "ow-components/ow-ajax.js");
      this.loadOWComponent(
        "owvc-common-styles",
        "components/owvc-common-styles.js"
      );
      this.loadOWComponent("trigger", "components/trigger.js");
      this.loadOWComponent("chatbot-icon", "components/chatbot-icon.js");
      this.loadOWComponent("bottom-sheet", "components/bottom-sheet.js");
      this.loadOWComponent("support-section", "components/support-section.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent("fp", "lib/fp.min.js");
      /**Website components */
      if (!this.isMobile) {
        this.loadOWComponent(
          "website-header",
          "components/fullview/website-header.js"
        );
        this.loadOWComponent("cities-sheet", "components/cities-sheet.js");
        this.loadOWComponent(
          "website-footer",
          "components/fullview/website-footer.js"
        );
        this.loadOWComponent(
          "cookie-consent",
          "components/fullview/cookie-consent.js"
        );
        this.loadOWComponent("web-search", "components/fullview/web-search.js");
        this.loadOWComponent("ow-spinner", "components/ow-spinner.js");
      }
    },
    loadHomeComponents() {
      this.loadOWComponent("ve-dashboard", "pages/ve-dashboard.js");
      this.loadOWComponent("story-view", "components/story-view.js");
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent("shimmer-blocks", "components/shimmer-blocks.js");
      this.loadOWComponent("seo-helper", "components/seo/seo-helper.js");
      this.loadOWComponent("cities-sheet", "components/cities-sheet.js");
      this.loadOWComponent("bottom-sheet", "components/bottom-sheet.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");

      this.loadOWComponent("faq-page", "pages/faq-page.js");
      if (!this.isMobile) {
        this.loadOWComponent(
          "website-header",
          "components/fullview/website-header.js"
        );
        this.loadOWComponent(
          "website-footer",
          "components/fullview/website-footer.js"
        );
      }
      this.loadOWComponent("ve-pitch", "pages/ve-pitch.js");
      if (this.isMobile) {
        this.loadOWComponent("bottom-nav-bar", "components/bottom-nav-bar.js");
      }
    },
    loadListingsComponents() {
      this.loadOWComponent("ve-listings", "pages/ve-listings.js");
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent("cities-sheet", "components/cities-sheet.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent(
        "vehicle-card-list",
        "components/vehicle-card-list.js"
      );
      this.loadOWComponent(
        "vehicle-info-card",
        "components/vehicle-info-card.js"
      );
      this.loadOWComponent("seo-helper", "components/seo/seo-helper.js");
      this.loadOWComponent(
        "special-banner",
        "components/fullview/special-banner.js"
      );
      this.loadOWComponent("checkbox", "components/checkbox.js");
      /**Website components */
      if (!this.isMobile) {
        this.loadOWComponent("carousel", "components/carousel.js");
        this.loadOWComponent(
          "filter-tags",
          "components/fullview/filter-tags.js"
        );
        this.loadOWComponent(
          "filter-left-sheet",
          "components/fullview/filter-left-sheet.js"
        );
        this.loadOWComponent(
          "double-thumb-slider",
          "components/double-thumb-slider.js"
        );
        this.loadOWComponent(
          "bread-crumbs",
          "components/fullview/bread-crumbs.js"
        );
      }
    },
    loadDetailViewComponents() {
      this.loadOWComponent("ve-details", "pages/ve-details.js");
      this.loadOWComponent(
        "test-drive-sheet",
        "components/test-drive-sheet.js"
      );
      this.loadOWComponent("addresses-sheet", "components/addresses-sheet.js");
      this.loadOWComponent(
        "three-sixty-view",
        "components/three-sixty-view.js"
      );
      this.loadOWComponent("image-card", "components/image-card.js");
      this.loadOWComponent("story-view", "components/story-view.js");
      this.loadOWComponent("stories", "components/stories.js");
      this.loadOWComponent("horizontal-list", "components/horizontal-list.js");
      this.loadOWComponent("info-cta", "components/info-cta.js");
      this.loadOWComponent("points-list", "components/points-list.js");
      this.loadOWComponent("carscore-badge", "components/carscore-badge.js");
      this.loadOWComponent("slider", "components/slider.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent(
        "emi-calculator-sheet",
        "components/emi-calculator-sheet.js"
      );
      this.loadOWComponent("single-slider", "components/single-slider.js");
      this.loadOWComponent("progress-bar", "components/progress-bar.js");
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent(
        "imperfections-sheet",
        "components/imperfections-sheet.js"
      );
      this.loadOWComponent(
        "imperfections-card",
        "components/imperfections-card.js"
      );
      this.loadOWComponent("checkbox", "components/checkbox.js");

      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent(
        "vehicle-info-card",
        "components/vehicle-info-card.js"
      );
      this.loadOWComponent("test-drive-cta", "components/test-drive-cta.js");
      this.loadOWComponent("seo-helper", "components/seo/seo-helper.js");
      this.loadOWComponent(
        "web-emi-calculator",
        "components/fullview/web-emi-calculator.js"
      );
      this.loadOWComponent(
        "web-features",
        "components/fullview/web-features.js"
      );
      this.loadOWComponent(
        "common-features",
        "components/fullview/common-features.js"
      );
      this.loadOWComponent("tabs", "components/tabs.js");
      this.loadOWComponent("sticky-btns", "components/sticky-btns.js");
      this.loadOWComponent("error-page", "components/error-page.js");
      this.loadOWComponent("date-picker", "components/date-picker.js");
      this.loadOWComponent(
        "retail-outlets-td-cta",
        "components/outlets/retail-outlets-td-cta.js"
      );
      /**Website components */
      if (!this.isMobile) {
        this.loadOWComponent(
          "bread-crumbs",
          "components/fullview/bread-crumbs.js"
        );
      }
      this.loadOWComponent(
        "details-gallery",
        "components/fullview/details-gallery.js"
      );
      this.loadOWComponent(
        "buy-back-info",
        "components/buyBack/buy-back-info.js"
      );
      this.loadOWComponent(
        "retail-outlets-info-card",
        "components/outlets/retail-outlets-info-card.js"
      );
    },
    loadFeaturesViewComponents() {
      this.loadOWComponent("ve-features", "pages/ve-features.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent("tabs", "components/tabs.js");
      this.loadOWComponent("points-list", "components/points-list.js");
      this.loadOWComponent(
        "common-features",
        "components/fullview/common-features.js"
      );
    },
    loadGalleryViewComponents() {
      this.loadOWComponent("ve-gallery", "pages/ve-gallery.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent("tabs", "components/tabs.js");
    },
    loadDriveViewComponents() {
      this.loadOWComponent("drive-status", "pages/booking/drive-status.js");
    },
    loadMapViewComponents() {
      this.loadOWComponent(
        "address-pin-map",
        "pages/test-drive/address-pin-map.js"
      );
      this.loadOWComponent(
        "address-search",
        "pages/test-drive/address-search.js"
      );
      this.loadOWComponent(
        "google-maps-api",
        "components/maps/google-maps-api.js"
      );
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent("address-gmap", "pages/test-drive/address-gmap.js");
      /**Website components */
      if (!this.isMobile) {
        this.loadOWComponent(
          "test-drive-banner",
          "components/fullview/test-drive-banner.js"
        );
      }
    },
    loadTestDriveViewComponents() {
      this.loadOWComponent(
        "ve-test-drive",
        "pages/test-drive/ve-test-drive.js"
      );
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      if (!this.isMobile) {
        this.loadOWComponent(
          "test-drive-banner",
          "components/fullview/test-drive-banner.js"
        );
      }
    },
    loadTestDriveBookingViewComponents() {
      this.loadOWComponent("ow-spinner", "components/ow-spinner.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent(
        "ve-test-drive-booking",
        "pages/test-drive/ve-test-drive-booking.js"
      );
      this.loadOWComponent(
        "ve-saved-address-list",
        "pages/test-drive/ve-saved-address-list.js"
      );
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent("date-picker", "components/date-picker.js");
      this.loadOWComponent("tabs", "components/tabs.js");
      this.loadOWComponent("address-gmap", "pages/test-drive/address-gmap.js");
      this.loadOWComponent(
        "address-search",
        "pages/test-drive/address-search.js"
      );
      this.loadOWComponent(
        "google-maps-api",
        "components/maps/google-maps-api.js"
      );
      this.loadOWComponent("horizontal-list", "components/horizontal-list.js");
      this.loadOWComponent(
        "ve-terms-conditions",
        "pages/ve-terms-conditions.js"
      );
      /**Website components */
      if (!this.isMobile) {
        this.loadOWComponent(
          "test-drive-banner",
          "components/fullview/test-drive-banner.js"
        );
      }
    },
    loadTestDriveBookingStatusViewComponents() {
      this.loadOWComponent("date-picker", "components/date-picker.js");
      this.loadOWComponent("tabs", "components/tabs.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent(
        "ve-test-drive-booking-status",
        "pages/test-drive/ve-test-drive-booking-status.js"
      );
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent("checkbox", "components/checkbox.js");
      this.loadOWComponent("reason-sheet", "components/reason-sheet.js");
      if (!this.isMobile) {
        this.loadOWComponent(
          "test-drive-banner",
          "components/fullview/test-drive-banner.js"
        );
      }
    },
    loadReserveViewComponents() {
      this.loadOWComponent(
        "reserve-booking",
        "pages/booking/reserve-booking.js"
      );
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent("coupon-mgmt", "components/coupon-mgmt.js");
      this.loadOWComponent("input-field", "components/input-field.js");
    },
    loadPaymentSuccessViewComponents() {
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent("ve-success", "pages/ve-success.js");
    },
    loadPaymentStatusViewComponents() {
      this.loadOWComponent("payment-status", "pages/booking/payment-status.js");
      this.loadOWComponent("consent-sheet", "pages/booking/consent-sheet.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent("checkbox", "components/checkbox.js");
      this.loadOWComponent("reason-sheet", "components/reason-sheet.js");
      this.loadOWComponent("coupon-mgmt", "components/coupon-mgmt.js");
      this.loadOWComponent("input-field", "components/input-field.js");
    },
    loadCheckoutReadyViewComponents() {
      this.loadOWComponent("checkout-ready", "pages/booking/checkout-ready.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent("coupon-mgmt", "components/coupon-mgmt.js");
      this.loadOWComponent("shimmer-blocks", "components/shimmer-blocks.js");
    },
    loadBuyBookingViewComponents() {
      this.loadOWComponent("buy-booking", "pages/buy-booking.js");
    },
    loadDeliveryDetailsViewComponents() {
      this.loadOWComponent("delivery-details", "pages/delivery-details.js");
      this.loadOWComponent("checkbox", "components/checkbox.js");
      this.loadOWComponent("input-field", "components/input-field.js");
      this.loadOWComponent("date-picker", "components/date-picker.js");
      this.loadOWComponent(
        "date-picker-static",
        "components/date-picker-static.js"
      );
      this.loadOWComponent("scroll-picker", "components/scroll-picker.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
    },
    loadDeliveryAddressDetailsViewComponents() {
      this.loadOWComponent(
        "delivery-address-details",
        "pages/test-drive/delivery-address-details.js"
      );
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent("input-field", "components/input-field.js");
      this.loadOWComponent("tabs", "components/tabs.js");
      this.loadOWComponent("date-picker", "components/date-picker.js");
      this.loadOWComponent(
        "test-drive-banner",
        "components/fullview/test-drive-banner.js"
      );
    },
    loadBillingDetailsViewComponents() {
      this.loadOWComponent("billing-details", "pages/billing-details.js");
      this.loadOWComponent("input-field", "components/input-field.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
    },
    loadCheckoutViewComponents() {
      this.loadOWComponent("checkout", "pages/checkout.js");
    },
    loadFullPayPolicyViewComponents() {
      this.loadOWComponent("fullpay-policy", "pages/fullpay-policy.js");
    },
    loadBookingSuccessfullViewComponents() {
      this.loadOWComponent(
        "booking-successfull",
        "pages/booking-successfull.js"
      );
    },
    loadPaymentVerificationViewComponents() {
      this.loadOWComponent(
        "payment-verification",
        "pages/payment-verification.js"
      );
    },
    loadStatusPageViewComponents() {
      this.loadOWComponent("status-page", "pages/status-page.js");
    },

    loadBookingHistoryViewComponents() {
      this.loadOWComponent("booking-history", "pages/booking-history.js");
      this.loadOWComponent("trigger", "components/trigger.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent("tabs", "components/tabs.js");
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent("bottom-sheet", "components/bottom-sheet.js");
      if (this.isMobile) {
        this.loadOWComponent("bottom-nav-bar", "components/bottom-nav-bar.js");
      }
    },
    loadDeliverySuccessViewComponents() {
      this.loadOWComponent("delivery-success", "pages/delivery-success.js");
      this.loadOWComponent("trigger", "components/trigger.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent("coupon-mgmt", "components/coupon-mgmt.js");
      this.loadOWComponent("input-field", "components/input-field.js");
    },
    loadSellerPitchComponents() {
      this.loadOWComponent(
        "ve-seller-pitch",
        "pages/seller-flow/ve-seller-pitch.js"
      );
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent(
        "short-message-sheet",
        "components/seller/short-message-sheet.js"
      );
      this.loadOWComponent("cities-sheet", "components/cities-sheet.js");
      this.loadOWComponent("horizontal-list", "components/horizontal-list.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent("checkbox", "components/checkbox.js");
    },
    loadSellerEntryComponents() {
      this.loadOWComponent(
        "ve-seller-entry",
        "pages/seller-flow/ve-seller-entry.js"
      );
      this.loadOWComponent(
        "seller-select",
        "components/seller/seller-select.js"
      );
      this.loadOWComponent("scroll-picker", "components/scroll-picker.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent("input-field", "components/input-field.js");
      this.loadOWComponent(
        "short-message-sheet",
        "components/seller/short-message-sheet.js"
      );
      this.loadOWComponent("checkbox", "components/checkbox.js");
      this.loadOWComponent(
        "ve-terms-conditions",
        "pages/ve-terms-conditions.js"
      );
    },
    loadSellerDetailsComponents() {
      this.loadOWComponent(
        "ve-seller-details",
        "pages/seller-flow/ve-seller-details.js"
      );
      this.loadOWComponent(
        "seller-car-data",
        "components/seller/seller-car-data.js"
      );
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent(
        "short-message-sheet",
        "components/seller/short-message-sheet.js"
      );
      // this.loadOWComponent("sorry-sheet", "components/seller/sorry-sheet.js");
    },

    loadSellerInitialPriceComponents() {
      this.loadOWComponent(
        "ve-seller-initial-price",
        "pages/seller-flow/ve-seller-initial-price.js"
      );
      this.loadOWComponent(
        "short-message-sheet",
        "components/seller/short-message-sheet.js"
      );
      this.loadOWComponent(
        "seller-select",
        "components/seller/seller-select.js"
      );
      // this.loadOWComponent(
      //   "price-sheet",
      //   "pages/seller-flow/ve-seller-price-sheet.js"
      // );
    },
    loadSellerPriceSheetComponents() {
      this.loadOWComponent("ow-ajax", "ow-components/ow-ajax.js");
      this.loadOWComponent(
        "listing-sheet",
        "components/seller/listing-sheet.js"
      );
      this.loadOWComponent(
        "ve-seller-choose-location",
        "pages/seller-flow/ve-seller-choose-location.js"
      );
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent(
        "short-message-sheet",
        "components/seller/short-message-sheet.js"
      );
      this.loadOWComponent(
        "ve-seller-price-sheet",
        "pages/seller-flow/ve-seller-price-sheet.js"
      );
      this.loadOWComponent("input-field", "components/input-field.js");
      this.loadOWComponent("checkbox", "components/checkbox.js");
    },
    loadSellerChooseLocation() {
      this.loadOWComponent(
        "ve-seller-choose-location",
        "pages/seller-flow/ve-seller-choose-location.js"
      );
      this.loadOWComponent(
        "short-message-sheet",
        "components/seller/short-message-sheet.js"
      );
      this.loadOWComponent(
        "reason-list-sheet",
        "components/seller/reason-list-sheet.js"
      );
      this.loadOWComponent("ow-spinner", "components/ow-spinner.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent(
        "ve-saved-address-list",
        "pages/test-drive/ve-saved-address-list.js"
      );
      this.loadOWComponent("scroll-picker", "components/scroll-picker.js");

      this.loadOWComponent(
        "detailed-address-form",
        "components/seller/detailed-address-form.js"
      );
      this.loadOWComponent(
        "date-picker-seller",
        "components/seller/date-picker-seller.js"
      );
      this.loadOWComponent("tabs", "components/tabs.js");
      this.loadOWComponent("address-gmap", "pages/test-drive/address-gmap.js");
      this.loadOWComponent(
        "address-search",
        "pages/test-drive/address-search.js"
      );
      this.loadOWComponent(
        "google-maps-api",
        "components/maps/google-maps-api.js"
      );
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
    },

    loadSellerInspectionAgent() {
      this.loadOWComponent(
        "ve-seller-inspection-agent",
        "pages/seller-flow/ve-seller-inspection-agent.js"
      );
      this.loadOWComponent(
        "short-message-sheet",
        "components/seller/short-message-sheet.js"
      );
      this.loadOWComponent("scroll-picker", "components/scroll-picker.js");
      this.loadOWComponent(
        "reason-list-sheet",
        "components/seller/reason-list-sheet.js"
      );
      this.loadOWComponent(
        "date-picker-seller",
        "components/seller/date-picker-seller.js"
      );
      this.loadOWComponent("tabs", "components/tabs.js");
      this.loadOWComponent(
        "ve-seller-choose-location",
        "pages/seller-flow/ve-seller-choose-location.js"
      );
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
    },
    loadSellerInspectionProgress() {
      this.loadOWComponent(
        "ve-seller-inspection-progress",
        "pages/seller-flow/ve-seller-inspection-progress.js"
      );
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
    },
    loadSellerEstimatedPrice() {
      this.loadOWComponent(
        "ve-seller-estimated-price",
        "pages/seller-flow/ve-seller-estimated-price.js"
      );
    },
    loadSellerUploadDocuments() {
      this.loadOWComponent(
        "ve-seller-upload-documents",
        "pages/seller-flow/ve-seller-upload-documents.js"
      );
    },
    loadSellerUploadHome() {
      this.loadOWComponent(
        "ve-seller-upload-home",
        "pages/seller-flow/ve-seller-upload-home.js"
      );
      this.loadOWComponent(
        "listing-sheet",
        "components/seller/listing-sheet.js"
      );
    },
    loadSellerBankDetails() {
      this.loadOWComponent(
        "ve-seller-bank-details",
        "pages/seller-flow/ve-seller-bank-details.js"
      );
      this.loadOWComponent(
        "short-message-sheet",
        "components/seller/short-message-sheet.js"
      );
    },
    loadSellerRejectReason() {
      this.loadOWComponent(
        "ve-seller-reject-reason",
        "pages/seller-flow/ve-seller-reject-reason.js"
      );
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
    },
    loadSellerNegotiation() {
      this.loadOWComponent(
        "ve-seller-negotiation",
        "pages/seller-flow/ve-seller-negotiation.js"
      );
    },
    loadSellerPickupSchedule() {
      this.loadOWComponent(
        "ve-seller-pickup-schedule",
        "pages/seller-flow/ve-seller-pickup-schedule.js"
      );
      this.loadOWComponent("scroll-picker", "components/scroll-picker.js");

      this.loadOWComponent(
        "ve-seller-choose-location",
        "pages/seller-flow/ve-seller-choose-location.js"
      );
      this.loadOWComponent(
        "date-picker-seller",
        "components/seller/date-picker-seller.js"
      );
      this.loadOWComponent("tabs", "components/tabs.js");
    },
    loadSellerPickupAgent() {
      this.loadOWComponent(
        "ve-seller-pickup-agent",
        "pages/seller-flow/ve-seller-pickup-agent.js"
      );
      this.loadOWComponent("scroll-picker", "components/scroll-picker.js");
      this.loadOWComponent(
        "listing-sheet",
        "components/seller/listing-sheet.js"
      );

      this.loadOWComponent(
        "short-message-sheet",
        "components/seller/short-message-sheet.js"
      );

      this.loadOWComponent(
        "reason-list-sheet",
        "components/seller/reason-list-sheet.js"
      );

      this.loadOWComponent(
        "date-picker-seller",
        "components/seller/date-picker-seller.js"
      );
      this.loadOWComponent("tabs", "components/tabs.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
    },
    loadSellerPickupComplete() {
      this.loadOWComponent(
        "ve-seller-pickup-complete",
        "pages/seller-flow/ve-seller-pickup-complete.js"
      );
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
    },
    loadSellerDashboard() {
      this.loadOWComponent(
        "ve-seller-dashboard",
        "pages/seller-flow/ve-seller-dashboard.js"
      );
      this.loadOWComponent("story-view", "components/story-view.js");
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent("horizontal-list", "components/horizontal-list.js");
      this.loadOWComponent("stories", "components/stories.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent("cities-sheet", "components/cities-sheet.js");
      this.loadOWComponent(
        "seller-select",
        "components/seller/seller-select.js"
      );
    },
    loadSellerConfirmCar() {
      this.loadOWComponent(
        "ve-seller-confirm-car",
        "pages/seller-flow/ve-seller-confirm-car.js"
      );
    },
    loadSellerComingSoon() {
      this.loadOWComponent(
        "ve-seller-coming-soon",
        "pages/seller-flow/ve-seller-coming-soon.js"
      );
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent("cities-sheet", "components/cities-sheet.js");
      if (!this.isMobile) {
        this.loadOWComponent(
          "website-header",
          "components/fullview/website-header.js"
        );
        this.loadOWComponent("cities-sheet", "components/cities-sheet.js");
        this.loadOWComponent(
          "website-footer",
          "components/fullview/website-footer.js"
        );
      }
    },
    loadDealerListingsComponents() {
      this.loadOWComponent("ve-dealer-listings", "pages/ve-dealer-listings.js");
      this.loadOWComponent("info-cta", "components/info-cta.js");
      this.loadOWComponent(
        "double-thumb-slider",
        "components/double-thumb-slider.js"
      );
      this.loadOWComponent("horizontal-list", "components/horizontal-list.js");
      this.loadOWComponent("carousel", "components/carousel.js");
      this.loadOWComponent("slider", "components/slider.js");
      this.loadOWComponent("sort-sheet", "components/sort-sheet.js");
      this.loadOWComponent("filter-sheet", "components/filter-sheet.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent("preference-card", "components/preference-card.js");
      this.loadOWComponent("carscore-badge", "components/carscore-badge.js");
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent(
        "vehicle-card-list",
        "components/vehicle-card-list.js"
      );
      this.loadOWComponent(
        "vehicle-info-card",
        "components/vehicle-info-card.js"
      );
      this.loadOWComponent("search-sheet", "components/search-sheet.js");
      this.loadOWComponent("seo-helper", "components/seo/seo-helper.js");
      this.loadOWComponent("filter-tags", "components/fullview/filter-tags.js");
      this.loadOWComponent(
        "special-banner",
        "components/fullview/special-banner.js"
      );
      this.loadOWComponent("cities-sheet", "components/cities-sheet.js");
      /**Website components */
      if (!this.isMobile) {
        this.loadOWComponent(
          "web-sort-sheet",
          "components/fullview/web-sort-sheet.js"
        );
        this.loadOWComponent(
          "filter-left-sheet",
          "components/fullview/filter-left-sheet.js"
        );
        this.loadOWComponent(
          "bread-crumbs",
          "components/fullview/bread-crumbs.js"
        );
      }
    },
    loadDealerLandingComponents() {
      this.loadOWComponent("ve-dealer-landing", "pages/ve-dealer-landing.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent("cities-sheet", "components/cities-sheet.js");
    },
    loadDealerDetailViewComponents() {
      this.loadOWComponent("ve-dealer-details", "pages/ve-dealer-details.js");
      this.loadOWComponent(
        "three-sixty-view",
        "components/three-sixty-view.js"
      );
      this.loadOWComponent("image-card", "components/image-card.js");
      this.loadOWComponent("story-view", "components/story-view.js");
      this.loadOWComponent("stories", "components/stories.js");
      this.loadOWComponent("horizontal-list", "components/horizontal-list.js");
      this.loadOWComponent("info-cta", "components/info-cta.js");
      this.loadOWComponent("points-list", "components/points-list.js");
      this.loadOWComponent("carscore-badge", "components/carscore-badge.js");
      this.loadOWComponent("slider", "components/slider.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent(
        "emi-calculator-sheet",
        "components/emi-calculator-sheet.js"
      );
      this.loadOWComponent("single-slider", "components/single-slider.js");
      this.loadOWComponent("progress-bar", "components/progress-bar.js");
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent(
        "imperfections-sheet",
        "components/imperfections-sheet.js"
      );
      this.loadOWComponent(
        "imperfections-card",
        "components/imperfections-card.js"
      );
      this.loadOWComponent("checkbox", "components/checkbox.js");

      this.loadOWComponent(
        "vehicle-info-card",
        "components/vehicle-info-card.js"
      );
      this.loadOWComponent("test-drive-cta", "components/test-drive-cta.js");
      this.loadOWComponent("slider", "components/slider.js");
      this.loadOWComponent("seo-helper", "components/seo/seo-helper.js");
      this.loadOWComponent(
        "web-emi-calculator",
        "components/fullview/web-emi-calculator.js"
      );
      this.loadOWComponent(
        "web-features",
        "components/fullview/web-features.js"
      );
      this.loadOWComponent(
        "common-features",
        "components/fullview/common-features.js"
      );
      this.loadOWComponent("tabs", "components/tabs.js");
      this.loadOWComponent("sticky-btns", "components/sticky-btns.js");
      /**Website components */
      if (!this.isMobile) {
        this.loadOWComponent(
          "bread-crumbs",
          "components/fullview/bread-crumbs.js"
        );
      }
      this.loadOWComponent(
        "details-gallery",
        "components/fullview/details-gallery.js"
      );

      this.loadOWComponent("input-field", "components/input-field.js");
    },
    loadBlogsComponents() {
      this.loadOWComponent("ve-blogs", "pages/ve-blogs.js");
      this.loadOWComponent("blog-card", "components/fullview/blog-card.js");
      this.loadOWComponent(
        "bread-crumbs",
        "components/fullview/bread-crumbs.js"
      );
    },
    loadBlogDetailsComponents() {
      this.loadOWComponent("ve-blog-details", "pages/ve-blog-details.js");
      this.loadOWComponent(
        "bread-crumbs",
        "components/fullview/bread-crumbs.js"
      );
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
    },
    loadUserDetails() {
      this.loadOWComponent("ve-user-details", "pages/ve-user-details.js");
      if (this.isMobile) {
        this.loadOWComponent("bottom-nav-bar", "components/bottom-nav-bar.js");
      }
    },
    loadTestDriveLocationSearchComponents() {
      this.loadOWComponent(
        "ve-td-location-search",
        "pages/test-drive/ve-td-location-search.js"
      );
      this.loadOWComponent("td-banner", "components/fullview/td-banner.js");
    },
    loadTestDriveAddressFormComponents() {
      this.loadOWComponent(
        "ve-td-address-form",
        "pages/test-drive/ve-td-address-form.js"
      );
      this.loadOWComponent(
        "google-maps-api",
        "components/maps/google-maps-api.js"
      );
      this.loadOWComponent("address-gmap", "pages/test-drive/address-gmap.js");
      this.loadOWComponent("input-field", "components/input-field.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent("td-banner", "components/fullview/td-banner.js");
    },
    loadTestDriveDatePickerComponents() {
      this.loadOWComponent(
        "ve-td-date-picker",
        "pages/test-drive/ve-td-date-picker.js"
      );
      this.loadOWComponent("td-banner", "components/fullview/td-banner.js");
      this.loadOWComponent("date-picker", "components/date-picker.js");
      this.loadOWComponent("tabs", "components/tabs.js");
    },
    loadPitch() {
      this.loadOWComponent("bottom-sheet", "components/bottom-sheet.js");
      this.loadOWComponent("faq-page", "pages/faq-page.js");
      if (!this.isMobile) {
        this.loadOWComponent(
          "website-header",
          "components/fullview/website-header.js"
        );
        this.loadOWComponent(
          "website-footer",
          "components/fullview/website-footer.js"
        );
      }
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent("ve-pitch", "pages/ve-pitch.js");
      if (this.isMobile) {
        this.loadOWComponent("bottom-nav-bar", "components/bottom-nav-bar.js");
      }
    },
    handleDeeplink() {
      const ROUTES = window.ROUTES;
      const url = this.linkGenerator(
        this.$route.query.action,
        this.$route.query.extra
      );
      if (url && url.path) {
        if (url.path.indexOf(ROUTES.DETAIL_VIEW.path) > -1) {
          this.handleRouteBasedLoading(ROUTES.DETAIL_VIEW.path);
        } else {
          this.handleRouteBasedLoading(ROUTES.HOME.path);
        }
        this.$router.replace(url.path);
      } else {
        this.handleRouteBasedLoading(this.$route.path);
        this.$router.replace("/");
      }
    },
    sanitizeParamsFromPath(path) {
      let subPath = path;
      if (path !== "/") {
        subPath = `/${path.split("/")[1]}`;
      }
      return subPath;
    },
    handleSSRRouteBasedLoading(path) {
      const ROUTES = JSON.parse(JSON.stringify(window.ROUTES));
      for (let key in ROUTES) {
        ROUTES[key]["path"] = this.sanitizeParamsFromPath(ROUTES[key]["path"]);
      }
      path = this.sanitizeParamsFromPath(path);

      switch (path) {
        case ROUTES.HOME.path: {
          this.loadHomeSSRComponents();
          break;
        }
        case ROUTES.DETAIL_VIEW.path: {
          this.loadDetailsSSRComponents();
          break;
        }
        default: {
          break;
        }
      }
    },
    handleRouteBasedLoading(path) {
      const ROUTES = JSON.parse(JSON.stringify(window.ROUTES));
      for (let key in ROUTES) {
        ROUTES[key]["path"] = this.sanitizeParamsFromPath(ROUTES[key]["path"]);
      }
      path = this.sanitizeParamsFromPath(path);

      switch (path) {
        case ROUTES.DETAIL_VIEW.path: {
          this.loadDetailViewComponents();
          break;
        }
        case ROUTES.FEATURES_VIEW.path: {
          this.loadFeaturesViewComponents();
          break;
        }
        case ROUTES.GALLERY_VIEW.path: {
          this.loadGalleryViewComponents();
          break;
        }
        case ROUTES.DRIVE_VIEW.path: {
          this.loadDriveViewComponents();
          break;
        }
        case ROUTES.RESERVE_VIEW.path: {
          this.loadReserveViewComponents();
          break;
        }
        case ROUTES.STATUS_PAGE.path: {
          this.loadStatusPageViewComponents();
          break;
        }
        case ROUTES.TEST_DRIVE_VIEW.path: {
          this.loadTestDriveViewComponents();
          break;
        }
        case ROUTES.TEST_DRIVE_BOOKING_VIEW.path: {
          this.loadTestDriveBookingViewComponents();
          break;
        }
        case ROUTES.BOOKING_HISTORY.path: {
          this.loadBookingHistoryViewComponents();
          break;
        }
        case ROUTES.DELIVERY_SUCCESS.path: {
          this.loadDeliverySuccessViewComponents();
          break;
        }
        case ROUTES.ADDRESS_PIN_MAP.path: {
          this.loadMapViewComponents();
          break;
        }
        case ROUTES.TEST_DRIVE_BOOKING_STATUS_VIEW.path: {
          this.loadTestDriveBookingStatusViewComponents();
          break;
        }
        case ROUTES.SUCCESS_VIEW.path: {
          this.loadPaymentSuccessViewComponents();
          break;
        }
        case ROUTES.PAYMENT_STATUS.path: {
          this.loadPaymentStatusViewComponents();
          break;
        }
        case ROUTES.BUY_BOOKING_VIEW.path: {
          this.loadBuyBookingViewComponents();
          break;
        }
        case ROUTES.DELIVERY_DETAILS.path: {
          this.loadDeliveryDetailsViewComponents();
          break;
        }
        case ROUTES.BILLING_DETAILS.path: {
          this.loadBillingDetailsViewComponents();
          break;
        }
        case ROUTES.CHECKOUT.path: {
          this.loadCheckoutViewComponents();
          break;
        }
        case ROUTES.FULLPAY_POLICY.path: {
          this.loadFullPayPolicyViewComponents();
          break;
        }
        case ROUTES.BOOKING_SUCCESSFULL.path: {
          this.loadBookingSuccessfullViewComponents();
          break;
        }
        case ROUTES.PAYMENT_VERIFICATION.path: {
          this.loadPaymentVerificationViewComponents();
          break;
        }
        case ROUTES.OFS_REVIEW.path:
        case ROUTES.OFS_REVIEW_FAILED.path: {
          this.loadOfsReviewComponents();
          break;
        }
        case ROUTES.PAYMENT_UPDATES.path: {
          this.loadPaymentUpdatesComponents();
          break;
        }
        case ROUTES.THREE_SIXTY_VIEW.path: {
          this.loadWebThreeSixtyViewComponents();
          break;
        }
        case ROUTES.CARE_PACKAGE.path: {
          this.loadCarePackageComponents();
          break;
        }
        case ROUTES.LOGIN.path: {
          this.loadLoginPageComponents();
          break;
        }
        case ROUTES.MY_ACCOUNTS.path: {
          this.loadMyAccountsComponents();
          break;
        }
        case ROUTES.FAQ_PAGE.path: {
          this.loadFaqPageComponents();
          break;
        }
        case ROUTES.PRIVACY_POLICY.path: {
          this.loadPrivacyPolicyComponents();
          break;
        }
        case ROUTES.TERMS_CONDITIONS.path: {
          this.loadTermsConditionComponents();
          break;
        }
        case ROUTES.SELL_CAR.path: {
          this.loadSellCarComponents();
          break;
        }
        case ROUTES.SELL_BIKE.path: {
          this.loadSellBikeComponents();
          break;
        }
        case ROUTES.COOKIE_POLICY.path: {
          this.loadCookiePolicyComponents();
          break;
        }

        case ROUTES.SELLER_PITCH.path: {
          this.loadSellerPitchComponents();
          break;
        }
        case ROUTES.SELLER_ENTRY.path: {
          this.loadSellerEntryComponents();
          break;
        }
        case ROUTES.SELLER_DETAILS.path: {
          this.loadSellerDetailsComponents();
          break;
        }
        case ROUTES.SELLER_INITIAL_PRICE.path: {
          this.loadSellerInitialPriceComponents();
          break;
        }
        case ROUTES.SELLER_PRICE_SHEET.path: {
          this.loadSellerPriceSheetComponents();
          break;
        }
        case ROUTES.SELLER_CHOOSE_LOCATION.path: {
          this.loadSellerChooseLocation();
          break;
        }
        case ROUTES.SELLER_INSPECTION_AGENT.path: {
          this.loadSellerInspectionAgent();
          break;
        }
        case ROUTES.SELLER_INSPECTION_PROGRESS.path: {
          this.loadSellerInspectionProgress();
          break;
        }
        case ROUTES.SELLER_ESTIMATED_PRICE.path: {
          this.loadSellerEstimatedPrice();
          break;
        }
        case ROUTES.SELLER_UPLOAD_DOCUMENTS.path: {
          this.loadSellerUploadDocuments();
          break;
        }
        case ROUTES.SELLER_UPLOAD_HOME.path: {
          this.loadSellerUploadHome();
          break;
        }
        case ROUTES.SELLER_BANK_DETAILS.path: {
          this.loadSellerBankDetails();
          break;
        }
        case ROUTES.SELLER_REJECT_REASON.path: {
          this.loadSellerRejectReason();
          break;
        }
        case ROUTES.SELLER_NEGOTIATION.path: {
          this.loadSellerNegotiation();
          break;
        }
        case ROUTES.SELLER_PICKUP_SCHEDULE.path: {
          this.loadSellerPickupSchedule();
          break;
        }
        case ROUTES.SELLER_PICKUP_AGENT.path: {
          this.loadSellerPickupAgent();
          break;
        }
        case ROUTES.SELLER_PICKUP_COMPLETE.path: {
          this.loadSellerPickupComplete();
          break;
        }
        case ROUTES.CHECKOUT_READY.path: {
          this.loadCheckoutReadyViewComponents();
          break;
        }
        case ROUTES.VIRTUAL_ACCOUNT.path: {
          this.loadVirtualAccountComponents();
          break;
        }
        case ROUTES.RESERVATION_CANCELLED.path: {
          this.loadReservationCancelledComponents();
          break;
        }
        case ROUTES.DELIVERY_ADDRESS_DETAILS.path: {
          this.loadDeliveryAddressDetailsViewComponents();
          break;
        }
        case ROUTES.SELLER_DASHBOARD.path: {
          this.loadSellerDashboard();
          break;
        }
        case ROUTES.SELLER_CONFIRM_CAR.path: {
          this.loadSellerConfirmCar();
          break;
        }
        case ROUTES.SELLER_COMING_SOON.path: {
          this.loadSellerComingSoon();
          break;
        }
        case ROUTES.BUY_BACK_PITCH.path: {
          this.loadBuyBackPitchComponents();
          break;
        }
        case ROUTES.YARD_SALES.path: {
          this.loadYardSalesComponents();
          break;
        }
        case ROUTES.DEALER_LANDING.path: {
          this.loadDealerLandingComponents();
          break;
        }
        case ROUTES.DEALER_LISTINGS.path: {
          this.loadDealerListingsComponents();
          break;
        }
        case ROUTES.DEALER_DETAIL_VIEW.path: {
          this.loadDealerDetailViewComponents();
          break;
        }
        case ROUTES.USER_DETAILS.path: {
          this.loadUserDetails();
          break;
        }
        case ROUTES.FAVOURITE_CARS.path: {
          this.loadFavouriteCarsPageComponents();
          break;
        }
        case ROUTES.BLOGS.path: {
          this.loadBlogsComponents();
          break;
        }
        case ROUTES.OUTLETS_LISTINGS.path: {
          this.loadOutletsListingsComponents();
          break;
        }
        case ROUTES.BLOG_DETAILS.path: {
          this.loadBlogDetailsComponents();
          break;
        }
        case ROUTES.TEST_DRIVE_LOCATION_SEARCH.path: {
          this.loadTestDriveLocationSearchComponents();
          break;
        }
        case ROUTES.TEST_DRIVE_ADDRESS_FORM.path: {
          this.loadTestDriveAddressFormComponents();
          break;
        }
        case ROUTES.TEST_DRIVE_DATE_PICKER.path: {
          this.loadTestDriveDatePickerComponents();
          break;
        }
        case ROUTES.PITCH.path: {
          this.loadPitch();
          break;
        }

        case ROUTES.HOME.path:
        default: {
          this.loadHomeComponents();
          break;
        }
      }
    },
    linkGenerator(action, extraParams) {
      if (linkMap && !Object.prototype.hasOwnProperty.call(linkMap, action)) {
        return null;
      }
      const extraJson = extraParams
        ? this.extraQueryToJson(extraParams)
        : { query: null };
      return linkMap && linkMap[action](extraJson);
    },
    extraQueryToJson(extra = "") {
      const aArray = extra.split(",");
      const extraJson = {};
      let queryString = "";
      for (let key in aArray) {
        if (key) {
          const keyArray = aArray[key].split("$$");
          keyArray[0] = keyArray[0] || "";
          const propertyName = keyArray[0];
          extraJson[propertyName] = keyArray[1];
          queryString += `${encodeURIComponent(
            propertyName
          )}=${encodeURIComponent(keyArray[1])}&`;
        }
      }
      return {
        json: extraJson,
        query: queryString
      };
    },
    loadOfsReviewComponents() {
      this.loadOWComponent("ve-ofs-review", "pages/ve-ofs-review.js");
    },
    loadPaymentUpdatesComponents() {
      this.loadOWComponent(
        "virtual-account-details",
        "components/virtual-account-details.js"
      );
      this.loadOWComponent("ve-payment-updates", "pages/ve-payment-updates.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
    },
    loadVirtualAccountComponents() {
      this.loadOWComponent(
        "virtual-account-details",
        "components/virtual-account-details.js"
      );
      this.loadOWComponent(
        "virtual-account",
        "pages/booking/virtual-account.js"
      );
    },
    loadReservationCancelledComponents() {
      this.loadOWComponent(
        "reservation-cancelled",
        "pages/booking/reservation-cancelled.js"
      );
      if (!this.isMobile) {
        this.loadOWComponent(
          "test-drive-banner",
          "components/fullview/test-drive-banner.js"
        );
      }
    },
    addBlockNavigationListener() {
      setTimeout(() => {
        window.addEventListener("popstate", this.stopBackNavigation, false);
      }, 200);
    },
    removeBlockNavigationListener() {
      window.removeEventListener("popstate", this.stopBackNavigation);
    },
    stopBackNavigation() {
      history.pushState(null, document.title, location.href);
    },
    loadWebThreeSixtyViewComponents() {
      this.loadOWComponent(
        "web-three-sixty-view",
        "components/fullview/web-three-sixty-view.js"
      );
      this.loadOWComponent(
        "three-sixty-view",
        "components/three-sixty-view.js"
      );
      this.loadOWComponent("tabs", "components/tabs.js");
    },
    loadLoginPageComponents() {
      this.loadOWComponent("login-page", "pages/login-page.js");
    },
    loadSellCarComponents() {
      this.loadOWComponent("ve-sell-car", "pages/seller-flow/ve-sell-car.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent("chatbot-icon", "components/chatbot-icon.js");
    },
    loadSellBikeComponents() {
      this.loadOWComponent("ve-sell-bike", "pages/seller-flow/ve-sell-bike.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent("chatbot-icon", "components/chatbot-icon.js");
    },
    loadMyAccountsComponents() {
      this.loadOWComponent("my-accounts", "pages/my-accounts.js");
      this.loadOWComponent(
        "my-accounts-tab",
        "components/fullview/my-accounts-tab.js"
      );
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      if (this.isMobile) {
        this.loadOWComponent("bottom-nav-bar", "components/bottom-nav-bar.js");
      }
      //Remove after merge
      this.loadOWComponent("trigger", "components/trigger.js");
      this.loadOWComponent("tabs", "components/tabs.js");
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
    },
    loadCarePackageComponents() {
      this.loadOWComponent("care-package", "pages/care-package.js");
    },
    loadFaqPageComponents() {
      this.loadOWComponent("faq-page", "pages/faq-page.js");
    },
    loadPrivacyPolicyComponents() {
      this.loadOWComponent("ve-privacy-policy", "pages/ve-privacy-policy.js");
    },
    loadTermsConditionComponents() {
      this.loadOWComponent(
        "ve-terms-conditions",
        "pages/ve-terms-conditions.js"
      );
    },
    loadCookiePolicyComponents() {
      this.loadOWComponent("ve-cookie-policy", "pages/ve-cookie-policy.js");
    },
    loadBuyBackPitchComponents() {
      this.loadOWComponent("ve-buy-back-pitch", "pages/ve-buy-back-pitch.js");
      this.loadOWComponent(
        "buy-back-criteria",
        "components/buyBack/buy-back-criteria.js"
      );
      this.loadOWComponent(
        "buy-back-faq",
        "components/buyBack/buy-back-faq.js"
      );
      this.loadOWComponent("buy-from-us", "components/buyBack/buy-from-us.js");
      this.loadOWComponent(
        "top-cars-assured-buyback",
        "components/buyBack/top-cars-assured-buyback.js"
      );
      this.loadOWComponent("table-info", "components/buyBack/table-info.js");
      this.loadOWComponent("slider", "components/slider.js");
      this.loadOWComponent("horizontal-list", "components/horizontal-list.js");
      this.loadOWComponent("shimmer-blocks", "components/shimmer-blocks.js");
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent("seo-helper", "components/seo/seo-helper.js");
      this.loadOWComponent(
        "vehicle-info-card",
        "components/vehicle-info-card.js"
      );
      this.loadOWComponent(
        "ve-terms-conditions",
        "pages/ve-terms-conditions.js"
      );
    },
    loadYardSalesComponents() {
      this.loadOWComponent("ve-yard-sales", "pages/ve-yard-sales.js");
      this.loadOWComponent("yard-info-card", "components/yard-info-card.js");
      this.loadOWComponent("yard-banner", "components/yard-banner.js");
      this.loadOWComponent("ve-details", "pages/ve-details.js");
    },
    loadFavouriteCarsPageComponents() {
      this.loadOWComponent("favourite-page", "pages/favourite-page.js");
      this.loadOWComponent("addresses-sheet", "components/addresses-sheet.js");
      this.loadOWComponent("slider", "components/slider.js");
      this.loadOWComponent("horizontal-list", "components/horizontal-list.js");
      this.loadOWComponent("shimmer-card", "components/shimmer-card.js");
      this.loadOWComponent("filter-sheet", "components/filter-sheet.js");
      this.loadOWComponent("search-sheet", "components/search-sheet.js");
      this.loadOWComponent(
        "vehicle-card-list",
        "components/vehicle-card-list.js"
      );
      this.loadOWComponent(
        "vehicle-info-card",
        "components/vehicle-info-card.js"
      );
      this.loadOWComponent("carousel", "components/carousel.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
      this.loadOWComponent("carousel", "components/carousel.js");
      this.loadOWComponent("bottom-sheet", "components/bottom-sheet.js");
      this.loadOWComponent(
        "circular-back-navbar",
        "components/circular-back-navbar.js"
      );
      this.loadOWComponent("cities-sheet", "components/cities-sheet.js");
      this.loadOWComponent("seo-helper", "components/seo/seo-helper.js");
      this.loadOWComponent(
        "test-drive-sheet",
        "components/test-drive-sheet.js"
      );
    },
    loadOutletsListingsComponents() {
      this.loadOWComponent(
        "ve-outlets-listings",
        "pages/ve-outlets-listings.js"
      );
      this.loadOWComponent(
        "retail-outlets-card-list",
        "components/outlets/retail-outlets-card-list.js"
      );
      this.loadOWComponent(
        "retail-outlets-info-card",
        "components/outlets/retail-outlets-info-card.js"
      );
      this.loadOWComponent("cities-sheet", "components/cities-sheet.js");
      this.loadOWComponent("error-sheet", "components/error-sheet.js");
    },
    getDeviceId() {
      return new Promise(function(resolve) {
        if (window.serverData.deviceId) {
          resolve(window.serverData.deviceId);
          return;
        }
        if (!window.FingerprintJS) {
          resolve("");
          return;
        }
        const fpPromise = window.FingerprintJS.load();
        fpPromise
          .then(fp => fp.get())
          .then(result => {
            const visitorId = result.visitorId;
            window.serverData.deviceId = visitorId;
            resolve(visitorId);
          });
      });
    },
    checkDomainIsOlaCars() {
      if (window.location.hostname.indexOf("ola.cars") === -1) {
        return false;
      }
      return true;
    },
    GTMEventPush(attributes, eventName, eventFor) {
      this.getDeviceId().then(deviceId => {
        const {
          utm_medium = localStorage.getItem("utm_medium"),
          utm_source = localStorage.getItem("utm_source"),
          utm_campaign = localStorage.getItem("utm_campaign")
        } = this.$route.query;

        let queryParams = { utm_campaign, utm_source, utm_medium };
        let _attributes = {
          event: eventName,
          pageUrl: this.$route.fullPath,
          pageTitle: this.$route.name,
          user_city:
            (this.selectedCityData && this.selectedCityData.name) ||
            localStorage.getItem("city") ||
            "",
          ...attributes,
          ...queryParams,
          oc_platform: this.getPlatform(),
          oc_user_id: "",
          oc_fingerprint_id: deviceId,
          oc_username: this.$store.state.userDetails.name || "",
          oc_useremail: this.$store.state.userDetails.email || "",
          oc_userphone: this.$store.state.userDetails.phone || ""
        };

        let userInfo = this.$store.state.userDetails;

        if (eventFor === "seller") {
          _attributes.sellerName = (userInfo && userInfo.name) || "";
          _attributes.userCity =
            (this.selectedCityData && this.selectedCityData.name) ||
            localStorage.getItem("city") ||
            "";
          _attributes.websiteUser = !this.isMobile;
        }

        Object.assign(_attributes, {
          oc_username: (userInfo && userInfo.name) || "NA",
          oc_useremail:
            (userInfo &&
              userInfo.identities &&
              userInfo.identities.find(i => i.type == "EMAIL").value) ||
            "NA",
          oc_userphone:
            (userInfo &&
              userInfo.identities &&
              userInfo.identities.find(i => i.type == "PHONE").value) ||
            "NA",
          oc_user_id: (userInfo && userInfo.userId) || "NA"
        });

        if (!window.dataLayer) {
          if (!window.gtmEventQueue) {
            window.gtmEventQueue = [];
          }
          window.gtmEventQueue.push(_attributes);
        } else {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(_attributes);
        }
      });
    },
    copyText(text) {
      const el = document.createElement("textarea");
      el.value = text;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    getMonthNames() {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      return monthNames;
    },
    getDayNames() {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return days;
    },
    formatDate(date) {
      let selectedDate = new Date(date);
      const monthNames = this.getMonthNames();
      return `${
        monthNames[selectedDate.getMonth()]
      } ${selectedDate.getDate()}, ${selectedDate.getFullYear()}`;
    },
    formatTime(startTime, endTime) {
      let daysNames = this.getDayNames();
      let monthNames = this.getMonthNames();
      let startDate = this.formatToNewDate(startTime);
      let endDate = this.formatToNewDate(endTime);
      let date =
        new Date(startDate).getDate() +
        " " +
        daysNames[new Date(startDate).getDay()];
      let hoursData =
        (new Date(startDate).getHours() % 12 || 12) +
        ":" +
        (new Date(startDate).getMinutes() || "00") +
        (new Date(startDate).getHours() >= 12 ? "PM" : "AM") +
        " - " +
        (new Date(endDate).getHours() % 12 || 12) +
        ":" +
        (new Date(endDate).getMinutes() || "00") +
        (new Date(endDate).getHours() >= 12 ? "PM" : "AM");

      let todayOrTomorrow =
        this.checkTodayTomorrow(
          startDate.getDate() + " " + monthNames[startDate.getMonth()]
        ) || startDate.getDate() + " " + monthNames[startDate.getMonth()];
      return {
        hours: hoursData,
        dateString: startDate,
        name: date,
        dateFull:
          todayOrTomorrow +
          ", " +
          (new Date(startDate).getHours() % 12 || 12) +
          ":" +
          (new Date(startDate).getMinutes() || "00") +
          (new Date(startDate).getHours() >= 12 ? "PM" : "AM")
      };
    },
    formatToNewDate(date) {
      return new Date(Number(date));
    },
    checkTodayTomorrow(date) {
      let monthNames = this.getMonthNames();
      var currentDate = new Date().getDate();
      var nextDate = new Date().getDate() + 1;
      var nextDay = monthNames[new Date().getMonth()];
      var day = monthNames[new Date().getMonth()];
      var currentFullDay = currentDate + " " + day;
      var currentFullNextDay = nextDate + " " + nextDay;
      if (currentFullDay == date) {
        return this.appStrings["today"];
      } else if (currentFullNextDay == date) {
        return this.appStrings["tomorrow"];
      }
      return null;
    },
    isEmptyObj(obj) {
      if (!obj) {
        return true;
      }
      return Object.keys(obj).length === 0;
    },
    getLeadSource() {
      if (this.isWebview) {
        return "ola_app";
      } else {
        return "ola_website";
      }
    },
    footerCtaClick(section) {
      this.recentlyViewedPageData({
        page: this.$route.name,
        section: section,
        item_clicked:
          this.currCtaState === "BUY_NOW" ? "Purchase_cta" : "Reserve_cta",
        car_id: this.$store.state.selectedCarDetails.carId
      });
      if (this.currCtaState === "BUY_NOW") {
        // TODO: add navigation for buy flow
        this.buyNow();
      } else if (this.currCtaState === "BOOK_NOW") {
        this.reserveBooking();
        this.branchEventTrigger("Car_Reserve_intent_shown");
        this.triggerTrackEvents("Car_Reserve_intent_shown", {
          car_details: this.carDetails,
          source: this.$store.state.lastRecentlyViewedPageData || null
        });
      }
    },
    reserveBooking() {
      /* Check if the user has already visited payment gateway once through 'method' query param and delete the query param to initiate fresh flow */
      if (this.$route.query.method) {
        delete this.$route.query.method;
      }
      if (this.carDetails && this.carDetails.carId) {
        localStorage.setItem("carId", this.carDetails.carId);
      }

      let navDetails = {
        name: "reserve-booking",
        params: {
          carDetails: this.carDetails
        }
      };
      if (!this.isWebview && localStorage.getItem("userLoggedIn") != "yes") {
        navDetails.query = {
          redirectUrl: "goBack",
          redirectAction: "BOOK_NOW"
        };
      }
      this.navigateToPage(navDetails);
    },
    buyNow() {
      if (this.carDetails && this.carDetails.carId) {
        localStorage.setItem("carId", this.carDetails.carId);
      }
      this.navigateToPage({
        name: "payment-status",
        params: {
          carDetails: this.carDetails
        }
      });
    },
    isDealer() {
      return this.$store.state.isDealer;
    },
    triggerAllImageLoadEvent(eventName, pageName) {
      let descSorted = this.$store.state.imagesSortedTime;
      let maxLoadTime = descSorted[0] ? descSorted[0].loadTime : "aborted";

      let earliestLoadStart = descSorted[0] ? descSorted[0].loadStartTime : 0;
      let latestLoadEnd = descSorted[0] ? descSorted[0].loadEndTime : 0;
      let cacheTrueCount = 0;
      let cacheFalseCount = 0;
      descSorted.forEach(each => {
        earliestLoadStart =
          earliestLoadStart < each.loadStartTime
            ? earliestLoadStart
            : each.loadStartTime;
        latestLoadEnd =
          latestLoadEnd > each.loadEndTime ? latestLoadEnd : each.loadEndTime;
        if (each.fromCache) {
          cacheTrueCount++;
        } else {
          cacheFalseCount++;
        }
      });

      let cummulativeLoadTime = latestLoadEnd - earliestLoadStart;
      let fromCache = cacheTrueCount > cacheFalseCount;
      let payload = {
        page_name: pageName,
        max_load_time: maxLoadTime,
        images_stats: descSorted,
        cummulative_load_time: cummulativeLoadTime || "aborted",
        from_cache: fromCache
      };
      this.triggerTrackEvents(eventName, payload);
    },
    stripSpecialCharacters(str) {
      return str.replace(/[\\:{}[\]," ]/g, "");
    },
    jenkinsOneAtATimeHash(keyString) {
      let hash = 0;
      let charIndex;
      for (charIndex = 0; charIndex < keyString.length; ++charIndex) {
        hash += keyString.charCodeAt(charIndex);
        hash += hash << 10;
        hash ^= hash >> 6;
      }
      hash += hash << 3;
      hash ^= hash >> 11;
      return (((hash + (hash << 15)) & 4294967295) >>> 0).toString(16);
    },
    getHashValue(obj) {
      let hashValue = JSON.stringify(obj);
      return this.jenkinsOneAtATimeHash(hashValue);
    },
    getFormattedPrice(price) {
      return (price / 100000).toFixed(2) + "L";
    },
    loadCommonScripts() {
      if (!window.isGTMLoaded && window.loadGTM) {
        window.loadGTM();
      }
      // if (!window.isHolofyLoaded && window.loadHolofy) {
      //   window.loadHolofy();
      // }
      if (!window.isMoengageLoaded && window.loadMoengage) {
        window.loadMoengage();
      }
      if (!window.isChatBotScriptLoaded && window.loadChatBotScript) {
        window.loadChatBotScript(() => {
          if (window.store) {
            setTimeout(() => {
              window.store.dispatch("SET_ZENDESK_SCRIPT_LOADED", true);
            }, 2000);
          }
        });
      }
    },
    createDynamicURLForDetailsPage(sourceObject) {
      if (!sourceObject) {
        return "";
      }
      let paramsString = "";
      if (sourceObject) {
        let keysToInclude = [
          { key: "brand", order: 1, prefix: "-", required: true },
          { key: "body_type", order: 3, prefix: "-", required: true },
          { key: "city", order: 10, prefix: "-in-", required: true },
          {
            key: "colour",
            order: 4,
            prefix: "-with-colour-",
            required: false
          },
          {
            key: "fuel_type",
            order: 5,
            prefix: "-with-fuel-type-",
            required: true
          },
          { key: "model", order: 2, prefix: "-", required: true },
          { key: "name", order: 0, prefix: "-", required: false },
          { key: "price", order: 7, prefix: "-at-Rs-", required: false },
          { key: "tag", order: 8, prefix: "-", required: false },
          {
            key: "transmission_type",
            order: 6,
            prefix: "-",
            required: false
          },
          { key: "variant", order: 9, prefix: "-", required: false }
        ];
        let sortedKeys = this.dynamicUrlReOrderKeys(
          keysToInclude,
          sourceObject
        );
        for (let key in sortedKeys)
          paramsString += `${sortedKeys[key]["prefix"]}${sourceObject[key]}`;

        paramsString = `${window.owConfig.domainUrl}/details/${sourceObject.car_id}-buy-used${paramsString}`;
        let userSessionId =
          window.sessionStorage &&
          window.sessionStorage.getItem("userSessionTime");
        if (userSessionId) {
          paramsString += `?userSessionTime=${btoa(userSessionId)}`;
        }
        return paramsString;
      }
    },
    dynamicUrlReOrderKeys(keyList, sourceObject) {
      let sortedKeys = keyList.sort((key1, key2) => key1.order - key2.order);
      let orderedItemList = {};
      for (let item of sortedKeys) {
        if (item.required) {
          orderedItemList[item["key"]] = {
            value: sourceObject[item["key"]],
            prefix: item["prefix"]
          };
        }
      }
      return orderedItemList;
    },
    preventRedirect(event) {
      if (this.redirectDetails) {
        event.preventDefault();
      }
    },
    createDynamicURLForBlogs(sourceObject) {
      if (!sourceObject) {
        return "";
      }
      let paramsString = "";
      if (sourceObject) {
        paramsString = `${window.owConfig.domainUrl}/blog-details/${sourceObject}?utm_source=blog&utm_campaign=${sourceObject}`;
        return paramsString;
      }
    },
    getSellerFlowCommomGTMParams() {
      let trafficSource = this.isWebView
        ? "PWA"
        : this.isMobile
        ? "mWeb"
        : "dWeb";
      return {
        traffic_source: trafficSource,
        utm_campaign: localStorage.getItem("utm_campaign") || "",
        utm_source: localStorage.getItem("utm_source") || "",
        utm_medium: localStorage.getItem("utm_medium") || ""
      };
    },
    capitalizeText(text) {
      if (!text) {
        return text;
      }
      return text[0].toUpperCase() + text.slice(1);
    },
    setGetDefaultCity(cityConfig) {
      let defaultCity = cityConfig.find(each => {
        return each.default_city;
      });
      localStorage.setItem("city", defaultCity.name);
      return defaultCity;
    },
    translateText(string) {
      return (
        (this.$store.state.appStrings &&
          this.$store.state.appStrings[string]) ||
        ""
      );
    }
  },
  watch: {
    isAllApiTriggered(newVal) {
      if (newVal) {
        this.loadCommonScripts();
      }
    }
  }
};
if (window.Vue) {
  Vue.mixin(appMixin);
}
